const transportationType = {
  TRUCKFTL: 1,
  MAIL: 2,
  TRAIN: 3,
  SEA: 4,
  AIR: 5,
  TRUCKLTL: 6,
  TRUCKMINIVAN: 7,
  TRUCKREEFER: 8,
  TRUCKHARDBODY: 9
};

export default transportationType;
